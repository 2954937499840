$(function () {
    new fileUpload();
});

class fileUpload {

    constructor($element) {
        if ($element) {
            this.create($element);
        } else {
            this.findAll();
        }
    }

    findAll() {
        let that = this;
        $('[data-component="fileUpload"]').each(function () {
            if (!$(this).hasClass('fileUpload__input')) {
                that.create($(this));
            }
        })
    }

    create($element) {
        let that = this;
        let settings = that.settings__get($element);
        let $container = that.create__Container();
        let $button = that.create__Button(settings);
        let $fakeInput = that.create__FakeInput(settings);


        $element
            .addClass('fileUpload__input')
            .wrap($container);

        $container = $element.parent('.fileUpload');

        $container.append($fakeInput);
        $container.append($button);

        settings.$input = $element;

        that.events(settings);
    }

    id__get($element) {
        let id;
        if ($element.attr('id') === undefined) {
            id = createId();
            $element.attr('id', id);
        } else {
            id = $element.attr('id');
        }
        return id;
    }

    create__Container() {
        return $('<div class="fileUpload"></div>');
    }

    create__Button(settings) {
        let btnClass = 'btn btn--lg btn--primary';
        let btnValue = 'file';

        if (settings.hasOwnProperty('btn')) {
            if (settings.btn.hasOwnProperty('class')) {
                btnClass += ' ' + settings.btn.class;
            }
            if (settings.btn.hasOwnProperty('value')) {
                btnValue = settings.btn.value;
            }
        }

        btnClass = 'fileUpload__btn ' + btnClass;

        return $('<label for="' + settings.id + '" class="' + btnClass + '">' + btnValue + '</label>');
    }

    create__FakeInput(settings) {
        let placeholder = '';

        if (settings.hasOwnProperty('placeholder')) {
            placeholder += settings.placeholder;
        }

        return $('<label for="' + settings.id + '" class="fileUpload__fakeInput placeholder">' + placeholder + '</label>');
    }

    settings__get($element) {
        let that = this;
        let out = {};
        if ($element.attr('data-fileUpload') !== undefined) {
            out = JSON.parse($element.attr('data-fileUpload'));
        }

        out.id = that.id__get($element);

        return out;
    }

    events(settings) {
        let that = this;

        settings.$input.on('change', function () {
            let input = $(this);
            let fileName = input.val().replace(/\\/g, '/').replace(/.*\//, '');
            let more = '';
            let nameLimit = 30;

            if (fileName.length > nameLimit) {
                more = '...';
            }

            fileName = fileName.substring(0, nameLimit) + more;

            that.updateLabel(settings, fileName);
        });
    }

    updateLabel(settings, fileName) {
        settings.$input.siblings('.fileUpload__fakeInput').removeClass('placeholder').text(fileName);
    }
}