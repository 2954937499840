$(function () {
    new CustomSelect();
});

class CustomSelect {

    constructor($element) {
        if ($element) {
            this.create($element);
        } else {
            this.findAll();
        }
    }

    findAll() {

        let that = this;
        $('[data-component="customSelect"]').each(function () {
            if (!$(this).hasClass('customSelect-element')) {
                that.create($(this));
            }
        })
    }

    create($element) {
        let that = this;
        let settings = that.settings__get($element);
        let $container = that.create__Container();
        let $arrow = that.create__Arrow();
        let $button = that.create__Button();

        settings.id = createId();

        let $list = that.create__List($element, settings);

        $element
            .addClass('customSelect-element')
            .wrap($container);

        $container = $element.parent('.customSelect');

        $container.append($button);
        $container.append($arrow);
        $container.append($list);

        settings.$container = $container;
        settings.$list = $list;

        if (settings.hasOwnProperty('withSearch') && settings.withSearch === 'true') {
            settings.$searchInput = that.create__SearchInput(settings);
            settings.$searchEngine = that.search__init(settings);

            $container.append(settings.$searchInput);
            that.search__event(settings);
        }

        that.updateByOrigin($element, $button, $list);

        $button.on('click', (e) => {
            e.preventDefault();
            that.toggle($container, $list, settings);
        });

        $element.on('click', (e) => {
            e.preventDefault();
            that.toggle($container, $list, settings);
        });

        $element.on('change', (e) => {
            e.preventDefault();
            that.updateByOrigin($element, $button, $list);
        });

        $list.on('click', 'button', (e) => {
            e.preventDefault();

            that.changeEvent($container, $element, $button, $list, $(e.target), settings)
        });
    }

    updateByOrigin($element, $button, $list) {
        let that = this;
        let value = $element.val();

        that.setValue($element, $button, $list, value);
    }

    changeEvent($container, $element, $button, $list, $item, settings) {
        let that = this;
        let value = $item.attr('data-value');

        that.setValue($element, $button, $list, value);
        that.toggle($container, $list, settings);
        $element.trigger("change");
    }

    setValue($element, $button, $list, value) {
        $list.find('button').each(function () {
            if ($(this).attr('data-value') === value) {
                $(this).addClass('isActive');

                let $li = $(this).parent('li');
                let text = $(this).text();

                if ($li.hasClass('sub')) {
                    let parent = $li.prevAll("li.hasSub:first");
                    text = parent.text() + ' / ' + text;
                }

                $button
                    .children('span')
                    .text(text)
            } else {
                $(this).removeClass('isActive');
            }
        });

        $element.val(value);
    }

    create__Container() {
        return $('<div class="customSelect"></div>');
    }

    create__Button() {
        return $('<button class="customSelect-button" type="button"><span></span></button>');
    }

    create__Arrow() {
        return $('<span class="customSelect-arrow"></span>');
    }

    create__SearchInput(settings) {
        let $out = $('<input class="customSelect-search" type="text"/>');

        if (settings.hasOwnProperty('searchPlaceholder')) {
            $out.attr('placeholder', settings.searchPlaceholder);
        }

        $out.attr('id', 'customSelect-search' + settings.id);

        return $out;
    }

    create__List($element, settings) {
        let that = this;
        let $listContainer = $('<div class="customSelect-listContainer"></div>');
        let $listInner = $('<ul class="customSelect-list"></ul>');

        if (settings.hasOwnProperty('withSearch') && settings.withSearch === 'true') {
            $listInner.attr('id', 'customSelect-list' + settings.id);
        }

        let items = $element.find('option');
        items.each(function () {
            $listInner.append(that.create__ListItem($(this)));
        });

        $listContainer.append($listInner);

        return $listContainer;
    }

    create__ListItem($item) {
        let $out = $('<li></li>');
        let $button = $('<button type="button" tabindex="-1"></button>');

        $button
            .text($item.text())
            .attr('data-value', $item.val());

        if ($item.attr('value') === undefined) {
            $out.addClass('prompt')
        }

        if ($item.attr('class') !== undefined) {
            $out.addClass($item.attr('class'))
        }

        $out.append($button);

        return $out;
    }

    settings__get($element) {
        let out = {};
        if ($element.attr('data-customSelect') !== undefined) {
            out = JSON.parse($element.attr('data-customSelect'));
        }
        return out;
    }

    toggle($container, $list, settings) {
        let that = this;
        if ($container.hasClass('isActive')) {
            that.close($container, $list)
        } else {
            that.closeOthers();
            that.open($container, $list, settings)
        }
    }

    open($container, $list, settings) {
        let that = this;
        $container.addClass('isActive');
        that.tabIndex($list, 0);

        if (settings.hasOwnProperty('withSearch') && settings.withSearch === 'true') {
            settings.$searchInput.val('').focus();
            settings.$searchEngine.search('');
        } else {
            $list.find('.isActive').focus();
        }
    }

    close($container, $list) {
        let that = this;
        $container.removeClass('isActive');
        that.tabIndex($list, -1);
    }

    closeOthers() {
        let that = this;
        $('.customSelect').each(function () {
            that.close($(this), $(this).children('.customSelect-list'))
        })
    }

    tabIndex($list, $index) {
        $list.find('button').each(function () {
            $(this).attr('tabindex', $index);
        })
    }

    search__init(settings) {
        return new Jets({
            contentTag: '#customSelect-list' + settings.id,
            searchSelector: '*',
            callSearchManually: true
        });
    }

    search__event(settings) {
        let that = this;
        settings.$searchInput.on('input', function () {
            settings.$searchEngine.search($(this).val());
        });

        settings.$searchInput.on('keydown', function (e) {
            if (e.keyCode === 9) {
                e.preventDefault();
                settings.$list.find('li').each(function () {
                    if ($(this).css('display') !== 'none') {
                        $(this).find('button').focus();
                        return false;
                    }
                })

            } else if (e.keyCode === 38 || e.keyCode === 27) {
                e.preventDefault();
                that.close(settings.$container, settings.$list);
            }
        });
    }
}