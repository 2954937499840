$(function () {
    new QuantityInput();
});

class QuantityInput {

    constructor($element) {
        if ($element) {
            this.create($element);
        } else {
            this.findAll();
        }
    }

    findAll() {

        let that = this;
        $('[data-component="quantityInput"]').each(function () {
            if (!$(this).hasClass('quantityInput-element')) {
                that.create($(this));
            }
        })
    }

    create($element) {
        let that = this;
        let $container = that.create__Container();

        let $fakeInput = that.create__Input();
        let $upArrow = that.create__Arrow('up');
        let $downArrow = that.create__Arrow('down');

        let unit = that.getUnit($element);

        $element
            .addClass('quantityInput-element')
            .wrap($container);

        $container = $element.parent('.quantityInput');

        $container.append($fakeInput);
        $container.append($upArrow);
        $container.append($downArrow);

        that.updateByOrigin($element, $fakeInput, unit);

        $upArrow.on('click', (e) => {
            e.preventDefault();
            that.changeEvent($element, $fakeInput, 'up', unit)
        });

        $downArrow.on('click', (e) => {
            e.preventDefault();
            that.changeEvent($element, $fakeInput, 'down', unit)
        });

        $element.on('change', (e) => {
            e.preventDefault();
            that.updateByOrigin($element, $fakeInput, unit);
        });

        $fakeInput.on('change', (e) => {
            e.preventDefault();

            let value = that.getValueFromUnit($fakeInput, unit);
            that.setValue($element, $fakeInput, value, unit);
        });

        $container.on('click', function (e) {
            if (e.target === e.currentTarget) {
                $fakeInput.focus().select();
            }
        });

        $container.on('focus', '*', function () {
            $container.addClass('focus');
        });

        $container.on('focusout', '*', function () {
            $container.removeClass('focus');
        });

        $element.on('focus', function () {
            $fakeInput.focus().select();
        });

        $fakeInput.on('keydown', function (e) {
            if (e.keyCode === 40) {
                e.preventDefault();
                that.changeEvent($element, $fakeInput, 'down', unit)
            } else if (e.keyCode === 38) {
                e.preventDefault();
                that.changeEvent($element, $fakeInput, 'up', unit)
            }
        });

    }

    getUnit($element) {
        let out = '';

        if ($element.attr('data-unit')) {
            out = $element.attr('data-unit');
        }
        return out;
    }

    updateByOrigin($element, $fakeInput, unit) {
        let that = this;
        
        that.setValue($element, $fakeInput, that.getValue($element), unit);
    }

    changeEvent($element, $fakeInput, direction, unit) {
        let that = this;
        let value = that.getValue($element);

        if (direction === 'up') {
            value += 1;
        } else {
            value -= 1;
        }

        value = that.validateValue(value);

        that.setValue($element, $fakeInput, value, unit);
        $element.trigger("change");
    }

    setValue($element, $fakeInput, value, unit) {
        let that = this;
        let formatedValue = that.formatValue(value, unit);

        $fakeInput.val(formatedValue);
        $element.val(value);
    }

    create__Container() {
        return $('<div class="quantityInput"></div>');
    }

    create__Arrow(type) {
        return $('<button type="button" class="quantityInput-arrow quantityInput-arrow--' + type + '"></button>');
    }

    create__Input() {
        return $('<input class="quantityInput-input" type="text">');
    }

    getValue($element) {
        let that = this;
        let value = $element.val();
        let out = 0;

        if (value !== '') {
            out = that.validateValue(parseInt(value));
        }

        return out;
    }

    getValueFromUnit($element, unit) {
        let that = this;
        let value = $element.val();
        let out = value;

        if (unit !== '') {
            out = value.split(" " + unit)[0];
        }

        return that.validateValue(parseInt(out));
    }

    formatValue(value, unit) {
        let out = value;

        if (unit !== '') {
            out += ' ' + unit;
        }

        return out;
    }

    validateValue(value) {
        let out = value;

        if (isNaN(value)) {
            out = 0;
        }

        if (value < 0) {
            out = 0;
        }

        return out;
    }
}