class pageNavigation {
    constructor($element) {
        if ($element) {
            this.init($element);
        } else {
            this.findAll();
        }
    }

    findAll() {
        let that = this;

        $('.pageNavigation').each(function () {
            that.init($(this));
        })
    }

    init($element) {
        this.settings = {};
        this.settings.$container = $element;
        this.settings.$subNavigation = this.settings.$container.find('.pageNavigation__sub');
        this.settings.$button = this.settings.$container.find('.pageNavigation__group-title');
        this.settings.activeClass = 'isActive';
        this.settings.visibleClass = 'pageNavigation--static';
        this.events(this.settings);
    }

    toggle(settings) {
        if (settings.$container.hasClass(settings.activeClass)) {
            settings.$container.removeClass(settings.activeClass);
            settings.$subNavigation.stop().slideUp();
        } else {
            settings.$container.addClass(settings.activeClass);
            settings.$subNavigation.stop().slideDown();
        }
    }

    events(settings) {
        let that = this;

        settings.$button.on('click', function () {
            if (!settings.$container.hasClass(settings.visibleClass)) {
                that.toggle(settings)
            }
        })
    }
}

$(function () {
    new pageNavigation();
});