$(function () {
    $('[data-component="passwordSwitch"]').on('click', function () {
        let target = $(this).data('target');
        let $target = $(target);

        if ($target.attr('type') === 'password') {
            $target.attr('type', 'text');
            $(this).addClass('isActive');
        } else {
            $target.attr('type', 'password');
            $(this).removeClass('isActive');
        }
    })
});