$(function () {
    new modalGallery();
});

class modalGallery {
    constructor() {
        this.events()
    }

    /**
     * Events
     */
    events() {
        let that = this;
        $('.productModal-galleryItems a').on('click', function (e) {
            let $parent = $(this).parent('li');

            // ignore download link
            if (!$parent.hasClass('withIcon')) {
                e.preventDefault();
            }

            // show item is is not active or link
            if (!$parent.hasClass('withIcon') && !$parent.hasClass('isActive')) {
                e.preventDefault();
                that.show($(this), $parent);
            }
        })
    }

    /**
     * Animate preview and control set new image
     * @param $elm jQuery elm
     * @param $parent jQuery elm
     */
    show($elm, $parent) {
        let that = this;
        let $gallery = $parent.closest('.productModal-gallery');
        let $preview = $gallery.find('.productModal-galleryPreview');
        let image = this.getImage($elm);

        const speed__out = 100;
        const speed__in = 250;

        $parent.addClass('isActive').siblings().removeClass('isActive');

        $preview.stop().animate({
            opacity: 0
        }, speed__out);

        setTimeout(function () {

            that.setImage($preview, image);

            $preview.stop().animate({
                opacity: 1
            }, speed__in);

        }, speed__out)
    }

    /**
     * Get image url from anchor
     * @param $elm jQuery elm
     */
    getImage($elm) {
        return $elm.attr('href');
    }

    /**
     * Set image new preview image
     * @param $elm jQuery elm
     * @param image url
     */
    setImage($elm, image) {
        $elm.children('img').attr('src', image);
    }
}