function basketSelect__open($elm) {
    const activeClass = 'isActive';
    let $variants = $elm.children('.basketSelect__variants');

    $variants.slideDown();
    $elm.addClass(activeClass);
}

function basketSelect__close($elm) {
    const activeClass = 'isActive';
    let $variants = $elm.children('.basketSelect__variants');

    $variants.slideUp();
    $elm.removeClass(activeClass);
}

function basketSelect__toggle($elm) {
    const activeClass = 'isActive';

    if ($elm.hasClass(activeClass)) {
        basketSelect__close($elm);
    } else {
        basketSelect__open($elm);
    }
}