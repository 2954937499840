class mobileNavigation {

    constructor() {
        this.$container = $('#mobileNavigation');
        this.$openButton = $('#pageHeader__toggle');
        this.$closeButton = $('#mobileNavigation__toggle');
        this.createOverlay(this.$container);
        this.events();
    }

    open() {
        this.$container.addClass('isActive');
        this.$overlay.fadeIn();
    }

    close() {
        this.$container.removeClass('isActive');
        this.$overlay.fadeOut();
    }

    createOverlay($parent) {
        let $elm = $('<div>');
        $elm
            .addClass('mobileNavigation__overlay')
            .attr('id', 'mobileNavigation__overlay');

        $parent.parent().append($elm);
        this.$overlay = $elm;
    }

    events() {
        let that = this;

        that.$openButton.on('click', function () {
            that.open();
        });

        that.$closeButton.on('click', function () {
            that.close();
        });

        that.$overlay.on('click', function () {
            that.close();
        });
    }
}

$(function () {
    new mobileNavigation();
});